<template>
  <div class="col-md-12">
    <div class="modal fade modal_cust show" v-if="modalId=='partnerDetails'" :class="modalState?'show':''" tabindex="-1" role="dialog" style="display: block;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{details.name}}
            </h5>
            <a class="close"  @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="editPartner" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Partner Detail</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="partnerImageUpdate" style="border: 1px dashed;">
                              <img v-if="formData.image"
                                   :src="formData.image"
                                   class="uploading-image"
                                   style="width: 5rem;height: 4rem;"
                              />
                              <span v-else style="padding: 1rem;">Image</span>
                            </label>
                            <input
                                    type="file"
                                    id="partnerImageUpdate"
                                    accept="image/jpeg"
                                    @change="base64Image"
                                    style="display:none;"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Name</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.name.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="Full Name"
                                    @blur="$v.formData.name.$touch()"
                                    v-model.trim="formData.name"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.name.$error">
                            <p class="error_level" v-if="!$v.formData.name.required">Name field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.name.minLength"
                            >At least 2 character name.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>URL</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.url.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="URL"
                                    @blur="$v.formData.url.$touch()"
                                    v-model.trim.number="formData.url"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.url.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.url.required"
                            >URL field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.url.minLength"
                            >At least 6 character url.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Status</label>
                          </div>
                          <div class="col-md-6">
                            <input
                                    type="radio"
                                    name="status"
                                    id="activatePartnerStatus"
                                    value="1"
                                    checked
                                    v-model="formData.status"
                            />
                            <label for="activatePartnerStatus">
                              <span class="ml-2">Activate</span>
                            </label>
                            <input
                                    type="radio"
                                    class="ml-4"
                                    name="status"
                                    id="deactivatePartnerStatus"
                                    value="0"
                                    v-model="formData.status"
                            />
                            <label for="deactivatePartnerStatus">
                              <span class="ml-2">Deactivate</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* disable eslint */
  import { mapGetters } from "vuex";
  import {
    maxLength,
    minLength,
    numeric,
    required,
  } from "vuelidate/lib/validators";
  export default {
    data() {
      return {
        details: [],
        formData: {
          name: "",
          url: "",
          image: null,
          status: 1,
        },
      };
    },
    validations: {
      formData: {
        name: { required, minLength: minLength(2), maxLength: maxLength(255) },
        url: {
          required,
          minLength: minLength(6),
        },
        status: { required, numeric },
      },
    },
    methods: {
      base64Image(e) {
        this.formData.image = null;
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.formData.image = e.target.result;
        };
      },
      editPartner() {
        this.$store.commit("setApiUrl", `api/partners/${this.details.id}`);
        this.$store.commit("updateData", this.formData);
      },
    },
    computed: {
      ...mapGetters([
        "processing",
        "eventMessage",
        "dataLists",
        "dataId",
        "modalId",
        "modalState",
      ]),
    },
    watch: {
      dataId(value) {
        this.details = this.dataLists.find(function (data) {
          return data.id == value;
        });
        this.formData.name = this.details.name;
        this.formData.url = this.details.url;
        this.formData.image = this.details.image;
        this.formData.status = this.details.status;
      }
    },
  };
</script>